const emailRegex = /\S+@\S+\.\S+/;
const onlyLettersRegex = /^[A-Za-zĄąĆćĘęŁłóÓŹźŻżńŃŚś ]*(?:-[A-Za-zĄąĆćĘęŁłóÓŹźŻżńŃŚś]*)*$/;
const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8})/
const phoneRegex = /^[+]*[(]{0,1}[0-9, +]{1,4}[)]{0,1}[-\s\.\/0-9]*$/;

export {
    onlyLettersRegex,
    passwordRegex,
    phoneRegex,
}
export default emailRegex;
