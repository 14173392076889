import emailRegex, { onlyLettersRegex, passwordRegex } from '../utilities/regex';
import validate from '../components/validator';

const register = () => {
  const langType = window.location.pathname.split('/')[1];
  const transAng = {
    emailValidate: 'Please enter a valid email address format',
    charValidate: 'This field contains invalid characters',
    passwordValidate: 'Please enter a valid password format',
  };
  const transPl = {
    emailValidate: 'Podaj prawidłowy format adresu email',
    charValidate: 'To pole zawiera nieprawidłowe znaki',
    passwordValidate: 'Podaj prawidłowy format hasła',
  };
  const text = langType === 'en' ? transAng : transPl;
  const mailInput = document.getElementById('id_email');
  const firstNameInput = document.getElementById('id_first_name');
  const lastNameInput = document.getElementById('id_last_name');
  const patternMail = new RegExp(emailRegex);
  const patternLetters = new RegExp(onlyLettersRegex);
  const patternPassword = new RegExp(passwordRegex);
  const passwordInput = document.getElementById('id_password1');
  const confirmPasswordInput = document.getElementById('id_password2');

  validate(mailInput, patternMail, text.emailValidate);
  validate(firstNameInput, patternLetters, text.charValidate);
  validate(lastNameInput, patternLetters, text.charValidate);

  const form = document.querySelector('#registerForm');

  form?.addEventListener('submit', (e) => {
    e.preventDefault();

    let nameValid = patternLetters.test(firstNameInput.value.trim());
    let lastNameisValid = patternLetters.test(lastNameInput.value.trim());
    let mailisValid = patternMail.test(mailInput.value.trim())
    if (nameValid && lastNameisValid && mailisValid) {
      form.submit();
    }
  })

  validate(passwordInput, patternPassword, text.passwordValidate);
  validate(confirmPasswordInput, patternPassword, text.passwordValidate);
};

export default register;
