const mobileMenu = () => {
  const hamburgerBtn = document.getElementById('hamburgerBtn');
  const menuMobile = document.getElementById('menuMobile');
  const menu = document.querySelector('.top-menu-panel');
  const logoPl = document.getElementById('logoPl');
  const logoWhite = document.querySelector('.logo-mobile');
  const mainMenuItem = document.querySelectorAll('.expand-menu-btn');
  const submenuLevel1 = document.querySelectorAll('ul.submenu');
  const topHeader = document.getElementById('topHeader');
  const selectedMenuItem =
    menuMobile !== null ? menuMobile.querySelector('.child.ancestor') : null;
  const submenuSelected =
    selectedMenuItem !== null
      ? selectedMenuItem.querySelector('ul.submenu')
      : null;
  const selectedColor =
    submenuSelected?.getAttribute('data-color') || '#940c0c';
  const loginPanel = document.querySelector('.top-menu-login');

  document.addEventListener('scroll', function (e) {
    if (window.pageYOffset > 230) {
      menu.classList.add('mobileMenuPanel');
    } else {
      if (!menu.classList.contains('show')) {
        menu.classList.remove('mobileMenuPanel');
      }
    }
  });

  if (hamburgerBtn) {
    hamburgerBtn.addEventListener('click', (e) => {
      hamburgerBtn.classList.toggle('open');
      menuMobile.classList.toggle('is-visible');
      menu.classList.toggle('show');
      logoWhite.classList.toggle('hidden');
      logoPl.classList.toggle('hidden');
      if (window.pageYOffset < 230) {
        menu.classList.toggle('mobileMenuPanel');
      }
      document.querySelector('body').classList.toggle('overflow-hidden');
      document.querySelector('html').classList.toggle('overflow-hidden');

      if (menu.classList.contains('show')) {
        topHeader.style.backgroundColor = selectedColor;
        menuMobile.style.backgroundColor = selectedColor;
      } else {
        topHeader.style.backgroundColor = '#fff';
        menuMobile.style.backgroundColor = '#fff';
      }

      if (menu.classList.contains('show') && loginPanel !== null) {
        loginPanel.style.display = 'none';
      } else if (loginPanel !== null) {
        loginPanel.style.display = 'block';
      }
    });
  }

  mainMenuItem.forEach((item) => {
    item.addEventListener('click', (event) => {
      let selectedId = event.target.id;
      event.target.classList.toggle('open');

      submenuLevel1.forEach((subItem) => {
        if (subItem.id == `${'submenu_' + selectedId}`) {
          subItem.classList.toggle('sticky-subItem');
          subItem.style.backgroundColor = selectedColor;
        }
      });
    });
  });
};

export default mobileMenu;
