const sidebarMenu = () => {
  const subMenuMainItems = document.querySelectorAll("span.menu_expand");
  const submenuLevel1Items = document.querySelectorAll("ul.sidebar_submenu");

  submenuLevel1Items.forEach((subItem) => {
    if (
      subItem.classList.contains("open")
    ) {
      subItem.style.display = "flex";
    }
  });

  subMenuMainItems.forEach((item) => {
    item.addEventListener("click", (event) => {
      const selectedId = event.target.id;
      submenuLevel1Items.forEach((subItem) => {
        if (
          subItem.id == `${"submenu_" + selectedId}` &&
          subItem.classList.contains("open")
        ) {
          subItem.style.display = "none";
          subItem.classList.toggle("open");
        } else if (subItem.id == `${"submenu_" + selectedId}`) {
          subItem.style.display = "flex";
          subItem.classList.toggle("open");
        }
      });
    });
  });
};

export default sidebarMenu;
