const validate = (element, pattern, msg) => {
    element?.addEventListener('focusout', (e) => {
        const check = pattern.test(e.target.value.trim());
        if (!check) {
            element.nextElementSibling.innerHTML = msg;
        } else {
            element.nextElementSibling.innerHTML = '';
        }
    })

    element?.addEventListener('keyup', (e) => {
        const check = pattern.test(e.target.value.trim());
        if (check) {
            element.nextElementSibling.innerHTML = '';
        }
    })
};

export default validate;
