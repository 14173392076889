import changeTheme from './assets/js/components/changeTheme';
import mobileMenu from './assets/js/components/mobileMenu';
import profile from './assets/js/pages/profile';
import setElementSticky from './assets/js/components/setElementSticky';
import setFontSize from './assets/js/components/setFontSize';
import sidebarMenu from './assets/js/components/sidebarMenu';
import register from './assets/js/pages/register';
import changeLang from './assets/js/components/changeLang';
import gallery from './assets/js/components/gallery';

document.addEventListener('DOMContentLoaded', () => {
  const navbar = document.getElementById('navbar');
  const userName = document.getElementById('userName');
  const menuListAllA = document.querySelectorAll('.menu-list .parent a');
  const sticky = navbar.offsetTop;
  const desktop = window.matchMedia('(min-width: 1200px)');

  const onScrollHandlerMatches = () => {
    setElementSticky(navbar, sticky);
    setElementSticky(userName, sticky);
    menuListAllA.forEach((elem) => setElementSticky(elem, sticky));
  };

  const onScrollHandlerNoMatches = () => {
    navbar.classList.remove('sticky');
    userName.classList.remove('sticky');
    menuListAllA.forEach((elem) => elem.classList.remove('sticky'));
  };

  window.onscroll = () => {
    desktop.matches ? onScrollHandlerMatches() : onScrollHandlerNoMatches();
  };

  mobileMenu();

  gallery();

  setFontSize();

  changeTheme();

  profile();

  sidebarMenu();

  register();

  changeLang();
});
