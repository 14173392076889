import emailRegex, {
  onlyLettersRegex,
  passwordRegex,
} from '../utilities/regex';

import validate from '../components/validator';

const profile = () => {
  const langType = window.location.pathname.split('/')[1];
  const transAng = {
    collapse: 'Collapse',
    expand: 'Expand',
    emailValidate: 'Please enter a valid email address format',
    charValidate: 'This field contains invalid characters',
    passwordValidate: 'Please enter a valid password format',
  };
  const transPl = {
    collapse: 'Zwiń',
    expand: 'Rozwiń',
    emailValidate: 'Podaj prawidłowy format adresu email',
    charValidate: 'To pole zawiera nieprawidłowe znaki',
    passwordValidate: 'Podaj prawidłowy format hasła',
  };
  const text = langType === 'en' ? transAng : transPl;
  const showPasswordBtn = document.getElementById('showPasswords');
  const passwordInputsWrap = document.getElementById('passwords');

  showPasswordBtn?.addEventListener('click', () => {
    passwordInputsWrap.classList.toggle('hidden');

    if (!passwordInputsWrap.classList.contains('hidden')) {
      showPasswordBtn.textContent = text.collapse;
    } else {
      showPasswordBtn.textContent = text.expand;
    }
  });

  const enableEditBtn = document.getElementById('enableEditBtn');
  const editButtons = document.getElementById('editFormButtons');
  const editCancelBtn = document.getElementById('editCancel');
  const userForm = document.querySelector('#userForm');
  const inputs = userForm?.querySelectorAll('input');
  let savesValue = {};
  inputs?.forEach((element) => {
    const name = element.name;
    const value = element.value;
    if (value !== '') {
      savesValue = {
        ...savesValue,
        [name]: value,
      };
    }
  });

  enableEditBtn?.addEventListener('click', (e) => {
    e.preventDefault();

    editButtons.classList.remove('hidden');
    enableEditBtn.classList.add('hidden');

    inputs.forEach((input) => input.removeAttribute('disabled'));
  });

  editCancelBtn?.addEventListener('click', () => {
    editButtons.classList.add('hidden');
    enableEditBtn.classList.remove('hidden');
    inputs.forEach((input) => {
      input.value = savesValue[input.name] ?? '';
      input.setAttribute('disabled', true);
    });
  });

  const passwordForm = document.getElementById('passwordsForm');
  const passwordCancelBtn = document.getElementById('editPasswordCancel');
  const passwordInputs = passwordForm?.querySelectorAll('input');

  passwordCancelBtn?.addEventListener('click', () => {
    passwordInputsWrap.classList.add('hidden');
    passwordInputs.forEach((input) => (input.value = ''));
  });

  const mailInput = document.getElementById('id_email');
  const firstNameInput = document.getElementById('id_first_name');
  const lastNameInput = document.getElementById('id_last_name');
  const oldPasswordInput = document.getElementById('id_old_password');
  const newPasswordInput = document.getElementById('id_new_password1');
  const newPassowrdInput2 = document.getElementById('id_new_password2');

  const patternMail = new RegExp(emailRegex);
  const patternLetters = new RegExp(onlyLettersRegex);
  const patternPassword = new RegExp(passwordRegex);

  validate(mailInput, patternMail, text.emailValidate);
  validate(firstNameInput, patternLetters, text.charValidate);
  validate(lastNameInput, patternLetters, text.charValidate);

  userForm?.addEventListener('submit', (e) => {
    e.preventDefault();

    let nameValid = patternLetters.test(firstNameInput.value.trim());
    let lastNameisValid = patternLetters.test(lastNameInput.value.trim());
    let mailisValid = patternMail.test(mailInput.value.trim());
    if (nameValid && lastNameisValid && mailisValid) {
      userForm.submit();
    }
  });

  validate(oldPasswordInput, patternPassword, text.passwordValidate);
  validate(newPasswordInput, patternPassword, text.passwordValidate);
  validate(newPassowrdInput2, patternPassword, text.passwordValidate);
};

export default profile;
