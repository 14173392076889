import { setCookie, getCookie } from '../utilities/cookie';

const changeTheme = () => {
  const changeThemeBtn = document.getElementById('changeThemeBtn');
  const darkModeIsRunning = getCookie('darkMode');
  const body = document.querySelector('body');

  darkModeIsRunning === 'true'
    ? body.classList.add('dark-mode')
    : body.classList.remove('dark-mode');

  if (changeThemeBtn) {
    changeThemeBtn.addEventListener('click', () => {
      body.classList.toggle('dark-mode');
      const darkModeIsRunning = getCookie('darkMode');
      darkModeIsRunning === 'true'
        ? setCookie('darkMode', false, false)
        : setCookie('darkMode', true, false);
    });
  }
};

export default changeTheme;
