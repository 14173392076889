
  const gallery = () => {

  let prevElement = null;
  let currentElement = null;

const onClickImage = (e) => {
    if (currentElement.classList.contains('clicked-item')) {
      elems.forEach((item) => {
        item.classList.remove('carousel-preview');
      });
      prevElement.classList.remove('clicked-item');
    } else {
      currentElement.classList.add('clicked-item');
      elems.forEach((item) => {
        item.classList.add('carousel-preview');
      });
    }
  };

  const elems = document.querySelectorAll('.carousel');
  var instance = M.Carousel.init(elems, {
    indicators: true,
    dist: 0,
    onCycleTo: function (el) {
      currentElement = el;
      if (prevElement) {
        prevElement.removeEventListener('click', onClickImage);
        elems.forEach((item) => {
          item.classList.remove('carousel-preview');
        });
        prevElement.classList.remove('clicked-item');
      }
      currentElement.addEventListener('click', onClickImage);
      prevElement = currentElement;
    },
  });
}

export default gallery;
