const changeLang = () => {
    const langType = window.location.pathname.split('/')[1];
    const logoPl = document.getElementById('logoPl');
    const logoPlKontrast = document.getElementById('logoPlKontrast');
    const logoEn = document.getElementById('logoEn');
    const logoEnKontrast = document.getElementById('logoEnKontrast');
    const logoBOPl = document.getElementById('logoBOPl');
    const logoBOPlkontrast = document.getElementById('logoBOPlKontrast');
    const logoBoEn = document.getElementById('logoBOEn');
    const logoBoEnKontrast = document.getElementById('logoBOEnKontrast');

    if (langType === 'pl') {
        logoPl?.classList.remove('hidden');
        logoPlKontrast?.classList.remove('hidden');
        logoEn?.classList.add('hidden');
        logoEnKontrast?.classList.add('hidden');
        logoBOPlkontrast?.classList.remove('logoBOPlkontrast');
        logoBOPl?.classList.remove('hidden');
        logoBoEn?.classList.add('hidden');
        logoBoEnKontrast?.classList.add('hidden');
    } else if(langType === 'en') {
        logoPl?.classList.add('hidden');
        logoPlKontrast?.classList.add('hidden');
        logoEn?.classList.remove('hidden');
        logoEnKontrast?.classList.remove('hidden');
        logoBOPl?.classList.add('hidden');
        logoBOPlkontrast?.classList.add('hidden');
        logoBoEn?.classList.remove('hidden');
        logoBoEnKontrast?.classList.remove('hidden');
        logoEn?.classList.remove('hidden');
    }
};

export default changeLang;
