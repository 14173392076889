export const setCookie = (name = '', value = null, reload = true) => {
  const day = new Date();
  const exdays = 31;
  day.setTime(day.getTime() + exdays * 24 * 60 * 60 * 1000);
  const expires = `expires=${day.toUTCString()}`;
  document.cookie = `${name}=${value};${expires};path=/`;
  if (reload) {
    window.location.reload();
  }
};

export const getCookie = (cname) => {
  const name = `${cname}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
};
