import { setCookie, getCookie } from '../utilities/cookie';

const setFontSize = () => {
  const buttons = document.querySelectorAll('.text-size button');

  if (buttons) {
    buttons.forEach((button) => {
      button.addEventListener('click', () => {
        let initValue = getCookie('fontSize') || 0;
        const type = button.getAttribute('data-type');

        if (type === 'increase' && initValue < 2) {
          initValue++;
          setCookie('fontSize', initValue);
        }
        if (type === 'reduce' && initValue > 0) {
          initValue--;
          setCookie('fontSize', initValue);
        }
      });
    });
  }
};

export default setFontSize;
